import React, { Fragment, useState } from 'react';
import { Helmet } from 'react-helmet';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/List";
import Collapse from "@material-ui/core/Collapse";
import { NavLink } from "react-router-dom";
import './style.css';


const menus = [
    {
        id: 1,
        title: 'Home',
        link: '/home',
    },
    {
        id: 2,
        title: 'About',
        link: '/about',
    },
    {
        id: 6,
        title: 'Service',
        link: '/service',
    },
    {
        id: 7,
        title: 'Project',
        link: '/project',
    },
    {
        id: 8,
        title: 'Contact',
        link: '/contact',
    }
]


const MobileMenu = () => {

    const [openId, setOpenId] = useState(0);
    const [menuActive, setMenuState] = useState(false);

    const handleClickAccueil = (event) => {
        window.scrollTo(10, 0);
        setMenuState(!menuActive);
    };

    const handleClickAboutUs = (event) => {
        window.scrollTo(10, 450);
        setMenuState(!menuActive);
    };

    const handleClickServices = (event) => {
        window.scrollTo(10, 1550);
        setMenuState(!menuActive);
    };

    const handleClickProjets = (event) => {
        window.scrollTo(10, 2900);
        setMenuState(!menuActive);
    };

    const handleClickContact = (event) => {
        window.scrollTo(10, 3900);
        setMenuState(!menuActive);
    };

    return (
        <div>
            <Helmet>
                <title>Mobile Menu</title>
            </Helmet>
            <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
                <div className="menu-close">
                    <div className="clox" onClick={() => setMenuState(!menuActive)}><i className="ti-close"></i></div>
                </div>

                <ul className="responsivemenu">
                    <ListItem className="active">
                        <NavLink className="active" onClick={handleClickAccueil}>Accueil</NavLink>
                    </ListItem>
                    <ListItem className="menu-item-has-children">
                        <NavLink className="active" onClick={handleClickAboutUs} >A propos de nous</NavLink>
                    </ListItem>
                    <ListItem className="menu-item-has-children">
                        <NavLink onClick={handleClickServices} >Nos Services</NavLink>
                    </ListItem>
                    <ListItem className="menu-item-has-children">
                        <NavLink onClick={handleClickProjets}>Nos Projets</NavLink>
                    </ListItem>
                    <ListItem className="menu-item-has-children">
                        <NavLink onClick={handleClickContact}>Contact</NavLink>
                    </ListItem>


                    {/* {menus.map((item, mn) => {
                        return (
                            <ListItem className={item.id === openId ? 'active' : null}  key={mn}>
                                {item.id === 1 ? 
                                    <NavLink onClick={ClickHandler(10, 0)} className="active"
                                    >{item.title}</NavLink> :
                                
                                    <NavLink onClick={ClickHandler(10, 500)} className="active"
                                    >{item.title}</NavLink>
                                }
                            </ListItem>
                        )
                    })} */}
                </ul>

            </div>

            <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
                <button type="button" className="navbar-toggler open-btn">
                    <span className="icon-bar first-angle"></span>
                    <span className="icon-bar middle-angle"></span>
                    <span className="icon-bar last-angle"></span>
                </button>
            </div>
        </div>
    )
}

export default MobileMenu;