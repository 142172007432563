import React from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Projects from "../../api/project";
import Projects2 from "../../api/project2";
import { Link } from "react-router-dom";
import SectionTitle2 from "../SectionTitle2/SectionTitle2";

const customStyles = {
  description: {
    fontSize: "0.9em",
    color: "black",
    backgroundColor: "white",
    textAlign: "center",
  },
  title: {
    fontSize: "2em",
    color: "black",
    backgroundColor: "white",
    textAlign: "center",
  },
  subTitle: {
    fontSize: "1.5em",
    color: "black",
    backgroundColor: "white",
    textAlign: "center",
  },
};

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        infinite: true,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const settings2 = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 2,
  slidesToScroll: 1,
  autoplay: true,
  responsive: [
    // {
    //     breakpoint: 1400,
    //     settings: {
    //         slidesToShow: 3,
    //         slidesToScroll: 1,
    //         infinite: true,
    //     }
    // },
    // {
    //     breakpoint: 1024,
    //     settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1,
    //         infinite: true,
    //     }
    // },
    // {
    //     breakpoint: 991,
    //     settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1
    //     }
    // },
    // {
    //     breakpoint: 767,
    //     settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 1
    //     }
    // },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const ClickHandler = () => {
  window.scrollTo(10, 0);
};

const ProjectSection2 = (props) => {
  return (
    <section
      className={`wpo-project-section-s2 section-padding ${props.pClass}`}
    >
      <Helmet>
        <title>Ipsodia</title>
      </Helmet>
      <div className="container">
        <SectionTitle2
          subTitle={"L'EXPERIENCE IPSODIA"}
          Title={"Exemples de réalisations"}
        />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col col-xs-12 sortable-project">
            <div className="wpo-project-container project-slider owl-carousel">
              <Slider {...settings}>
                {/* {Projects.slice(4, 9).map((project, prj) => ( */}
                {Projects.map((project, prj) => (
                  <div className="grid" key={prj}>
                    <div className="wpo-project-item">
                      <img
                        src={project.pImg}
                        alt="Ipsodia réalise votre application Web ou Métier"
                        className="img img-responsive"
                      />
                      <div className="wpo-project-text">
                        <h3 style={customStyles.title}>{project.title}</h3>
                        <p style={customStyles.subTitle}>{project.subTitle}</p>
                        <br />
                        <p style={customStyles.description}>
                          {project.description}
                        </p>
                        {/* <h3><Link onClick={ClickHandler} to={`/project-single/${project.Id}`}>{project.title}</Link></h3> */}
                        {/* <Link onClick={ClickHandler} className="arrow-btn" to={`/project-single/${project.Id}`}><i
                                                className="fi flaticon-right-arrow-1"></i></Link> */}
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ marginTop: "2.5em" }}>
        <SectionTitle2 subTitle={""} Title={"Et de projets en cours"} />
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col col-lg-6 col-xs-12 sortable-project">
            <div className="wpo-project-container project-slider owl-carousel">
              <Slider {...settings2}>
                {/* {Projects.slice(4, 9).map((project, prj) => ( */}
                {Projects2.map((project, prj) => (
                  <div className="grid" key={prj}>
                    <div className="wpo-project-item">
                      <img
                        src={project.pImg}
                        alt="Ipsodia réalise votre application Web ou Métier"
                        className="img img-responsive"
                      />
                      <div className="wpo-project-text">
                        <h3>
                          <Link to={project.site} target="_blank">
                            {project.title}
                          </Link>
                        </h3>
                        <span>
                          <Link to={project.site} target="_blank">
                            {project.subTitle}
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectSection2;
