import pimg1 from "../images/project/laresidence.png";
import pimg2 from "../images/project/ventegroupee.png";

const Projects2 = [
  {
    Id: "1",
    pImg: pimg1,
    title: "Application Web",
    subTitle: "Site de présentation d'un Hôtel-Restaurant",
    site: "https://residence.ipsodia.com",
  },
  {
    Id: "2",
    pImg: pimg2,
    title: "Application Web",
    subTitle: "Site e-commerce de Vente groupée Audio",
    site: "https://vg.ipsodia.com",
  },
];

export default Projects2;
