import React from 'react'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom'
import abimg from '../../images/about/ab2.jpg'
import SectionTitle from "../SectionTitle/SectionTitle";

const ClickHandler = (props) => {
    window.scrollTo(10, 0);
}


const About4 = (props) => {
    return (
        <section className="wpo-about-section-s4 pt-120 pb-120 ">
            <div className="container">
                <Helmet>
                    <title>Ipsodia</title>
                </Helmet>
                <div className="wpo-about-inner">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-5 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-text" id="about-id">
                                <SectionTitle subTitle={'Binevenue chez Ipsodia'} Title={'Agence digitale de création d\'applications web ou mobile'} />
                                {/* <h4>BIENVENUE CHEZ IPSODIA</h4>
                                <h2>Agence digitale de création d'applications web ou mobile</h2> */}
                                <p>Nous avons choisi de nous spécialiser dans l'étude, la réalisation et la mise en place de <b>solutions "sur-mesure"</b> afin d'aligner le produit aux objectifs spécifiques du client dans une démarche collaborative.<br/><br/>
                                Nous recueillons et analysons vos besoins avant et pendant le projet pour générer de la valeur dans les <b>meilleurs délais</b> et au <b>meilleur coût</b>.<br/><br/>
                                Le client est <b>impliqué</b> dans la démarche projet depuis la phase d'analyse jusqu'au déploiement du produit, en passant par les phases de validation et par formation des utilisateurs.</p>
                                <div className="text-sub-wrap">
                                    <div className="text-sub-item">
                                        <i className="fi flaticon-tax"></i>
                                        <h5><u>Notre ambition</u> : vous fournir une solution de qualité qui répond à vos besoins et optimise la valeur ajoutée</h5>
                                    </div>
                                </div>
                                {/* <Link onClick={ClickHandler} className="theme-btn" to="/service">Nos Services</Link> */}
                            </div>
                        </div>
                        <div className="col-xl-7 col-lg-6 col-md-12 col-12">
                            <div className="wpo-about-wrap">
                                <div className="wpo-about-img">
                                    <div className="inner-img">
                                        <img src={abimg} alt="Ipsodia vous embarque dans le projet pour réaliser un produit bien aligné à vos besoins" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="shape-ab"></div>
        </section>
    )
}

export default About4;