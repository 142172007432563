import React from 'react'
import { Link } from 'react-router-dom'
import abimg2 from '../../images/about/4.png'
import shape1 from '../../images/ab-shape-1.png'
import shape2 from '../../images/ab-shape-2.png'

const ClickHandler = () => {
    window.scrollTo(10, 0);
}


const About = (props) => {
    return(
        <section className={`wpo-about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-wrap">
                            {/* <div className="wpo-about-img"> */}
                                {/* <img src={props.abimg} alt=""/> */}
                                {/* <div className="wpo-ab-shape-1"><img src={shape1} alt=""/></div>
                                <div className="wpo-ab-shape-2"><img src={shape2} alt=""/></div> */}
                                {/* <div className="wpo-ab-img-2"> */}
                                    <img src={abimg2} alt=""/>
                                {/* </div> */}
                                <div className="wpo-about-exprience">
                                    <h3>Guy, Product Manager chez Ipsodia</h3>
                                </div>
                            {/* </div> */}
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-12 col-12">
                        <div className="wpo-about-text">
                            <h4>Bienvenue à l'agence Ipsodia</h4>
                            <h2><b>Nos professionnels <span> expérimentés</span> vous accompagnent</b>
                            </h2>
                            <p>L'agence Ipsodia se positionne comme le <b>partenaire de confiance</b> de chaque 
                                client souhaitant lui confier son projet digital.<br/>
                                Avec plus de 20 années d'expérience en développement d'applications et conduite de projets à son actif, 
                                notre <b>product manager</b> sera votre <b>interlocuteur privilégié</b> sur tous les aspects de la relation.<br/>
                                Il aura à coeur d'être attentif à vos besoins et disponible pour <b>accompagner </b> 
                                vos équipes utilisatrices tout au long du cycle de vie de votre application. 
                                </p>
                            <Link onClick={ClickHandler} className="theme-btn" to="/about">Aller plus loin</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;