// import pimg1 from '../images/project/img-1.jpg'
// import pimg2 from '../images/project/img-2.jpg'
// import pimg3 from '../images/project/img-3.jpg'
// import pimg4 from '../images/project/img-31.jpg'
import pimg1 from "../images/project/chronopost-home.png";
import pimg2 from "../images/project/Evreux_logo.svg.png";
import pimg3 from "../images/project/Givenchy-Logo-PNG-1.png";
import pimg4 from "../images/project/Logo_Societe.com.png";
import pimg5 from "../images/project/Continental.jpg";
// import pimg6 from '../images/project/img-5.jpg'
// import pimg7 from '../images/project/img-6.jpg'
// import pimg8 from '../images/project/img-7.jpg'
// import pimg9 from '../images/project/img-8.jpg'
// import pimg10 from '../images/project/1.png'
// import pimg11 from '../images/project/2.png'
// import pimg12 from '../images/project/3.png'
// import pimg13 from '../images/project/4.png'

import ps1img1 from "../images/project-single/1.jpg";
import ps1img2 from "../images/project-single/2.jpg";
import ps1img3 from "../images/project-single/3.jpg";
import ps1img4 from "../images/project-single/4.jpg";
import ps1img5 from "../images/project-single/1.jpg";
// import ps1img6 from '../images/project-single/2.jpg'
// import ps1img7 from '../images/project-single/3.jpg'
// import ps1img8 from '../images/project-single/5.jpg'
// import ps1img9 from '../images/project-single/6.jpg'

import psub1img1 from "../images/project-single/p1.jpg";
import psub1img2 from "../images/project-single/p2.jpg";

const Projects = [
  {
    Id: "1",
    pImg: pimg4,
    ps1img: ps1img4,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Application Web",
    subTitle: "Site grand public societe.com",
    description:
      "Développement du site grand public de société.com d'ILIAD, leader de l'information légale et financière en France. Le site est un portail d'information sur les entreprises françaises, il permet de consulter les informations légales et financières des entreprises françaises. Il propose également des services de veille et de surveillance des entreprises.",
  },
  {
    Id: "2",
    pImg: pimg2,
    ps1img: ps1img2,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Application métier",
    subTitle: "Télésurveillance du réseau de distribution d'eau",
    description:
      "Développement d'un système de télésurveillance du réseau de distribution d'eau pour la ville d'Evreux gérant 60 sites (réservoirs, stations de pompage, stations de relevage) avec automatisation de la régulation du niveau de remplissage des réservoirs.",
  },
  {
    Id: "3",
    pImg: pimg3,
    ps1img: ps1img3,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Application métier",
    subTitle: "Gestion des formules de produits et parfums",
    description:
      "Développement d'une application métier pour la gestion des formules de produits et parfums au laboratoire Givenchy de Beauvais.",
  },
  {
    Id: "4",
    pImg: pimg1,
    ps1img: ps1img1,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Application métier",
    subTitle: "Gestion des Contrats Clients Pro",
    description:
      "Développement d'une application métier pour la gestion des contrats de clients professionnels de Chronopost, filiale du groupe La Poste. Génération automatique des contrats personnalisés avec les grilles tarifaires en fonction des besoins des clients.",
  },
  {
    Id: "5",
    pImg: pimg5,
    ps1img: ps1img5,
    psub1img1: psub1img1,
    psub1img2: psub1img2,
    title: "Application métier",
    subTitle: "Gestion de parcs de pneus véhicules Poids Lourd",
    description:
      "Développement d'une application métier pour la gestion des parcs de pneus de véhicules Poids Lourd de Continental, leader mondial de la fabrication de pneus. Estimation de la durée de vie des pneus selon une formule logarithmique.",
  },
  //  {
  //     Id:'6',
  //     pImg:pimg6,
  //     ps1img:ps1img6,
  //     psub1img1:psub1img1,
  //     psub1img2:psub1img2,
  //     title:'Investment planning',
  //     subTitle:'Business Consulting',
  //  },
  //  {
  //     Id:'7',
  //     pImg:pimg7,
  //     ps1img:ps1img7,
  //     psub1img1:psub1img1,
  //     psub1img2:psub1img2,
  //     title:'Business Analysis',
  //     subTitle:'Business Consulting',
  //  },
  //  {
  //     Id:'8',
  //     pImg:pimg8,
  //     ps1img:ps1img8,
  //     psub1img1:psub1img1,
  //     psub1img2:psub1img2,
  //     title:'Financial Advices',
  //     subTitle:'Business Consulting',
  //  },
  //  {
  //     Id:'9',
  //     pImg:pimg9,
  //     ps1img:ps1img9,
  //     psub1img1:psub1img1,
  //     psub1img2:psub1img2,
  //     title:'Investment planning',
  //     subTitle:'Business Consulting',
  //  },
  //  {
  //     Id:'10',
  //     pImg:pimg10,
  //     ps1img:ps1img6,
  //     psub1img1:psub1img1,
  //     psub1img2:psub1img2,
  //     title:'Investment planning',
  //     subTitle:'Business Consulting',
  //  },
  //  {
  //     Id:'11',
  //     pImg:pimg11,
  //     ps1img:ps1img7,
  //     psub1img1:psub1img1,
  //     psub1img2:psub1img2,
  //     title:'Business Analysis',
  //     subTitle:'Business Consulting',
  //  },
  //  {
  //     Id:'12',
  //     pImg:pimg12,
  //     ps1img:ps1img8,
  //     psub1img1:psub1img1,
  //     psub1img2:psub1img2,
  //     title:'Financial Advices',
  //     subTitle:'Business Consulting',
  //  },
  //  {
  //     Id:'13',
  //     pImg:pimg13,
  //     ps1img:ps1img9,
  //     psub1img1:psub1img1,
  //     psub1img2:psub1img2,
  //     title:'Investment planning',
  //     subTitle:'Business Consulting',
  //  },
];

export default Projects;
