import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";

const apiUrl = process.env.REACT_APP_API_URL;
console.log("apiUrl", apiUrl);

const ContactForm = () => {
  const [forms, setForms] = useState({
    name: "",
    email: "",
    subject: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = (e) => {
    console.log("submitHandler");
    e.preventDefault();
    if (validator.allValid()) {
      validator.hideMessages();
      console.log("Avant fetch");
      fetch(`${apiUrl}/api/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(forms),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.text();
        })
        .then((res) => {
          alert("Votre Email a bien été envoyé ! Merci de nous avoir contacté");
        })
        .catch((error) => {
          console.error("Erreur lors de l'envoi de l'email:", error);
          alert(error);
        });

      console.log("Après fetch");
      setForms({
        name: "",
        email: "",
        subject: "",
        phone: "",
        message: "",
      });
    } else {
      validator.showMessages();
    }
  };

  return (
    <form
      onSubmit={(e) => submitHandler(e)}
      className="contact-validation-active"
    >
      <div className="row">
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.name}
              type="text"
              name="name"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Votre Nom"
            />
            {validator.message("name", forms.name, "required|alpha_space")}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.email}
              type="email"
              name="email"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Votre Email"
            />
            {validator.message("email", forms.email, "required|email")}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-lg-12 col-12">
          <div className="form-field">
            <input
              value={forms.phone}
              type="phone"
              name="phone"
              onBlur={(e) => changeHandler(e)}
              onChange={(e) => changeHandler(e)}
              placeholder="Votre Téléphone"
            />
            {validator.message("phone", forms.phone, "required|phone")}
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col col-lg-12 col-12">
          <textarea
            onBlur={(e) => changeHandler(e)}
            onChange={(e) => changeHandler(e)}
            value={forms.message}
            type="text"
            name="message"
            placeholder="Votre Message"
          ></textarea>
          {validator.message("message", forms.message, "required")}
        </div>
      </div>
      <div className="submit-area">
        <button type="submit" className="theme-btn">
          Envoyer
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
