import simg from '../images/service/1.jpg'
import simg2 from '../images/service/2.jpg'
import simg3 from '../images/service/3.jpg'
import simg4 from '../images/service/4.jpg'

// import sImgS1 from '../images/service-single/img-1.jpg'
import sImgS1 from '../images/service-single/online-shopping-website.jpg' // 897x521
import sImgS2 from '../images/service-single/img-2.jpg'
import sImgS3 from '../images/service-single/img-3.jpg'
import sImgS4 from '../images/service-single/img-4.jpg'

import sSingleimg1 from '../images/service-single/2.jpg'
import sSingleimg2 from '../images/service-single/3.jpg'
import fImg1 from '../images/features/img-1.jpg'
import fImg2 from '../images/features/img-2.jpg'




const Services = [
    {
        Id: '2',
        sImg:fImg2,
        // sImg:simg2,
        sImgS:sImgS2,
        sTitle: 'Application Métier',
        description:'Votre application est à destination des utilisateurs de votre entreprise.', 
        des2:'Les cas d\'usage de votre activité doivent être gérés de façon optimale. Si les options du marché ne répondent pas à vos exigences, l\'agence Ipsodia est votre partenaire pour réaliser la solution idéale.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-team',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    {
        Id: '1',
        sImg:fImg1,
        // sImg:simg,
        sImgS:sImgS1,
        sTitle: 'Site Web ou App Mobile',
        description:'Votre application s\'adresse au grand public ou à une population ciblée.',
        des2: 'Nos analystes et équipes de développeurs professionnels prennent en charge vos exigences particulières (maintenabilité, évolutivité, sécurité...) et fonctionnalités spécifiques ou complexes.',
        des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
        icon:'flaticon-advisor',
        ssImg1:sSingleimg1,
        ssImg2:sSingleimg2,
    },
    // {
    //     Id: '3',
    //     sImg:simg3,
    //     sImgS:sImgS3,
    //     sTitle: 'Tax Management',
    //     description:'Lorem Ipsum is simply dummy text of the the industreys standard dummy text ever scrambled type specimen book.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-taxes',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // },
    // {
    //     Id: '4',
    //     sImg:simg4,
    //     sImgS:sImgS4,
    //     sTitle: 'Business Analysis',
    //     description:'Lorem Ipsum is simply dummy text of the the industreys standard dummy text ever scrambled type specimen book.',
    //     des2:'Lacus, etiam sed est eu tempus need Temer diam congue laoret .',
    //     des3:'One way to categorize the activities is in terms of the professional’s area of expertise such as competitive analysis, corporate strategy the activities',
    //     icon:'flaticon-tax',
    //     ssImg1:sSingleimg1,
    //     ssImg2:sSingleimg2,
    // }
]    

export default Services;