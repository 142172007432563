import React, {Fragment} from 'react';
import { Helmet } from 'react-helmet';
import About4 from '../../components/about4/about4';
import Footer from '../../components/footer/Footer';
import Hero4 from '../../components/hero4/Hero4';
import Navbar2 from '../../components/Navbar2/Navbar2'
import ProjectSection2 from '../../components/ProjectSection2/ProjectSection2';
import Scrollbar from '../../components/scrollbar/scrollbar';
import ServiceSectionS3 from '../../components/ServiceSectionS3/ServiceSectionS3';
import Logo from '../../images/logo-2.png'

const HomePage5 =() => {

    return(
        <Fragment>
            <Helmet>
                <title>Ipsodia</title>
            </Helmet>
            <Navbar2 topbarNone={'wpo-header-style-4'} Logo={Logo}/>
            <Hero4/>
            <About4/>
            <ServiceSectionS3/>
            <ProjectSection2/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage5;