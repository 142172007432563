import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import HeaderTopbar from "../HeaderTopbar/HeaderTopbar";
import MobileMenu from "../MobileMenu/MobileMenu";
import ContactForm from "../ContactForm/ContactForm";

const Header2 = (props) => {
  const [menuActive, setMenuState] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const SubmitHandler = (e) => {
    e.preventDefault();
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const handleClickAccueil = (event) => {
    window.scrollTo(10, 0);
  };

  const handleClickAboutUs = (event) => {
    window.scrollTo(10, 900);
  };

  const handleClickServices = (event) => {
    window.scrollTo(10, 1800);
  };

  const handleClickProjets = (event) => {
    window.scrollTo(10, 2400);
  };

  const handleClickContact = (event) => {
    window.scrollTo(10, 3200);
  };

  // const handleClick = (event) => {
  //     const targetId = event.target.getAttribute("href").substring(1); // Récupère l'identifiant de la cible (sans le '#')
  //     console.log(targetId);

  //     const targetElement = document.getElementById(targetId); // Récupère l'élément cible par son identifiant

  //     if (targetElement) {
  //         event.preventDefault(); // Empêche la navigation par défaut du lien
  //         window.scrollTo({
  //         top: targetElement.offsetTop,
  //         behavior: "smooth", // Pour un défilement fluide
  //         });
  //     }
  // };

  function Popup({ isOpen, onClose }) {
    if (!isOpen) return null;

    return (
      <div className="popup">
        <div className="popup-content">
          {<ContactForm />}
          {/* Votre formulaire ici */}
          <button onClick={onClose}>Fermer</button>
        </div>
      </div>
    );
  }

  const handleClick = (event) => {
    const href = event.target.getAttribute("href"); // Récupère la valeur de l'attribut "href"

    if (href && href.includes("#")) {
      const parts = href.split("#");
      const targetId = parts[1]; // Récupère le texte après le caractère "#"
      console.log(targetId);

      const targetElement = document.getElementById(targetId); // Récupère l'élément cible par son identifiant

      if (targetElement) {
        event.preventDefault(); // Empêche la navigation par défaut du lien
        const offset = 0; // Ajoutez le décalage souhaité en pixels
        window.scrollTo({
          top: targetElement.offsetHeight - offset,
          behavior: "smooth", // Pour un défilement fluide
        });
      }
    }
  };

  const clickPopupHandler = () => {
    setIsPopupOpen(true);
    <ContactForm />;
    // ... autres logiques si nécessaire
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <header id="header" className={props.topbarNone}>
      {/* <HeaderTopbar /> */}
      <div className={`wpo-site-header ${props.hclass}`}>
        <Helmet>
          <title>Header Page</title>
        </Helmet>
        <nav className="navigation navbar navbar-expand-lg navbar-light">
          <div className="container-fluid">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-3 col-3 d-lg-none dl-block">
                <div className="mobail-menu">
                  <MobileMenu />
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-6">
                <div className="navbar-header">
                  <Link
                    onClick={ClickHandler}
                    className="navbar-brand"
                    to="/home"
                  >
                    <img src={props.Logo} alt="" />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-1 col-1">
                <div
                  id="navbar"
                  className="collapse navbar-collapse navigation-holder"
                >
                  <button className="menu-close">
                    <i className="ti-close"></i>
                  </button>
                  <ul className="nav navbar-nav mb-2 mb-lg-0">
                    <li className="menu-item-has-children">
                      <Link onClick={handleClickAccueil}>Accueil</Link>
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={handleClickAboutUs}>A propos de nous</Link>
                      {/* <Link onClick={handleClick} to="#about-id">A propos de nous</Link> */}
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={handleClickServices}>Nos Services</Link>
                      {/* <Link onClick={handleClick} to="#services-id">Nos Services</Link> */}
                    </li>
                    <li className="menu-item-has-children">
                      <Link onClick={handleClickProjets}>Nos Projets</Link>
                    </li>
                    {/* <li>
                      <Link onClick={handleClickContact}>Contact</Link>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-lg-2 col-md-2 col-2">
                <div className="header-right">
                  {/* <div className="header-search-form-wrapper">
                                        <div className="cart-search-contact">
                                            <button onClick={() => setMenuState(!menuActive)} className="search-toggle-btn"><i
                                                className={`fi ti-search ${menuActive ? "ti-close" : "fi "}`}></i></button>
                                            <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
                                                <form onSubmit={SubmitHandler}>
                                                    <div>
                                                        <input type="text" className="form-control"
                                                            placeholder="Search here..." />
                                                        <button type="submit"><i
                                                            className="fi flaticon-search-interface-symbol"></i></button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div> */}

                  <div className="close-form">
                    <Link onClick={handleClickContact} className="theme-btn">
                      Nous contacter
                    </Link>
                  </div>
                  {/* <Popup isOpen={isPopupOpen} onClose={closePopup} /> */}
                </div>
              </div>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header2;
